
















































import { postController } from '@/modules/common/dialogs/post/post-controller'
import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { recruitmentPostController } from '../../dialogs/recruitment-post-controller'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({}) postStore!: PostStore
  @Prop({ default: false }) enableBlind!: boolean
  @Prop({ default: false }) isRctAdmin
  postController = postController
  recruitmentPostController = recruitmentPostController
}
